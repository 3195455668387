<template>
  <div class='container'>
    <h2>Edit VehicleModels  <b>Model Id:{{editingVehicleModels.Id}}</b></h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>

    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>

    <button @click='deleteVehicleModels()' type='button' class='btn btn-danger'>Delete</button>

    <div class='row'>
      <div class='col-md-6'>
        <label class for='Name'>Name</label>
        <input class='form-control' id='Name' name='Name' v-model='editingVehicleModels.Name' placeholder=' ' tabindex='0'
          type='text'>

      </div>
      <div class='col-md-6'>

          <ImageUploader  label="Image" v-model="editingVehicleModels.Image" />

       
      </div>
      <div class='col-md-6'>
        <label class for='ImageMeta'>ImageMeta</label>
        <input class='form-control' id='ImageMeta' name='ImageMeta' v-model='editingVehicleModels.ImageMeta'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class=' col-md-6'><label class='' for='Commercial'>Commercial</label>
        <br />
        <b-form-checkbox v-model='editingVehicleModels.Commercial'></b-form-checkbox>
        <div class=''><small></small></div>
      </div>
      <div class='col-md-6'>
        <label class for='LastUpdated'>LastUpdated</label>
        <input class='form-control' id='LastUpdated' name='LastUpdated' v-model='editingVehicleModels.LastUpdated'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='Category'>Category</label>
        <input class='form-control' id='Category' name='Category' v-model='editingVehicleModels.Category' placeholder=' '
          tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='StartingPrice'>StartingPrice</label>
        <input class='form-control' id='StartingPrice' name='StartingPrice' v-model='editingVehicleModels.StartingPrice'
          placeholder=' ' tabindex='0' type='text'>
  <label class for='StartingPriceNamibia'>StartingPriceNamibia</label>
                <input
          class="form-control"
          id="StartingPriceNamibia"
          name="StartingPriceNamibia"
          v-model="editingVehicleModels.StartingPriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
          <label class for='StartingPriceBotswana'>StartingPriceBotswana</label>
            <input
          class="form-control"
          id="StartingPriceBotswana"
          name="StartingPriceBotswana"
          v-model="editingVehicleModels.StartingPriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
          <label class for='StartingPriceSwaziland'>StartingPriceSwaziland</label>
              <input
          class="form-control"
          id="StartingPriceSwaziland"
          name="StartingPriceSwaziland"
          v-model="editingVehicleModels.StartingPriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />

      </div>
      <div class='col-md-6'>
        <label class for='StartingPriceMonthly'>StartingPriceMonthly</label>
        <input class='form-control' id='StartingPriceMonthly' name='StartingPriceMonthly' v-model='editingVehicleModels.StartingPriceMonthly'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='DisplayOrder'>DisplayOrder</label>
        <input class='form-control' id='DisplayOrder' name='DisplayOrder' v-model='editingVehicleModels.DisplayOrder'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='VideoId'>VideoId</label>
        <input class='form-control' id='VideoId' name='VideoId' v-model='editingVehicleModels.VideoId' placeholder=' '
          tabindex='0' type='text'>

      </div>

      <div class='col-md-4'>
        <ImageUploader :pdf="true"  label="Brochure Link" v-model="editingVehicleModels.BrochureLink" />
      </div>

      <div class='col-md-4'>
     
       <ImageUploader :pdf="true" label="SpecSheet Link" v-model="editingVehicleModels.SpecSheetLink" />
      </div>

      <div class='col-md-4'>
       <ImageUploader :pdf="true" label="SpecSheet Link" v-model="editingVehicleModels.AccessoriesLink" />
      </div>

      <!-- <div class='col-md-6'>
        <label class for='SpecSheetLink'>SpecSheetLink</label>
        <input class='form-control' id='SpecSheetLink' name='SpecSheetLink' v-model='editingVehicleModels.SpecSheetLink'
          placeholder=' ' tabindex='0' type='text'>

      </div> -->

      <div class='col-md-6'>
        <label class for='MainDescription'>Main Description</label>
        <textarea class='form-control' id='MainDescription' name='MainDescription' v-model='editingVehicleModels.MainDescription' placeholder=' ' tabindex='0'></textarea>

      </div>

      <div class='col-md-6'>
        <label class for='GalleryDescription'>Gallery Description</label>
        <textarea class='form-control' id='GalleryDescription' name='GalleryDescription' v-model='editingVehicleModels.GalleryDescription' placeholder=' ' tabindex='0'></textarea>

      </div>

      <div class='col-md-6'>
        <label class for='ModelsDescription'>Models Description</label>
        <textarea class='form-control' id='ModelsDescription' name='ModelsDescription' v-model='editingVehicleModels.ModelsDescription' placeholder=' ' tabindex='0'></textarea>

      </div>

      <div class='col-md-6'>
        <label class for='FeaturesDescription'>Features Description</label>
        <textarea class='form-control' id='FeaturesDescription' name='FeaturesDescription' v-model='editingVehicleModels.FeaturesDescription' placeholder=' ' tabindex='0'></textarea>

      </div>      
    </div>
    <div class='row'>
      <div class='col-md-6'>
         <label class for='FeaturesDescription'>Click to Buy Vehicle Model</label>
       <VehicleModelSelectC2B v-model="editingVehicleModels.ClickToBuyVehicleModelId"></VehicleModelSelectC2B>
      </div>     
    </div>

    <br />
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
  import _ from 'lodash';
  import ImageUploader from "@/components/custom/ImageUploader.vue";
  import FileUploader from '@/components/custom/FileUploader.vue';
  import DocumentUploader from '@/components/custom/DocumentUploader.vue';
    import VehicleModelSelectC2B from '@/components/custom/VehicleModelSelectC2B.vue';
  import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  };
  miniToastr.init({
    types: toastTypes
  });

  export default {
    name: 'EditVehicleModels',

    data() {
      return {
        imageBytes: {},
        selectedFile: {},
        originalData: {},
        modifiedData: {},
        uploadedImageData: {},
        uploadedImageUrl: null,
        editingImage: false,
        editingBrochureLink: false,
        uploadedBrochureLinkData: {},
        uploadedBrochureLinkUrl: null,
        editingSpecSheetLink: false,
        uploadedSpecSheetLinkData: {},
        uploadedSpecSheetLinkUrl: null,
        editingAccessoriesLink: false,
        uploadedAccessoriesLinkData: {},
        uploadedAccessoriesLinkUrl: null,

      };
    },
    components: {
      FileUploader,
      DocumentUploader,
      ImageUploader,
      VehicleModelSelectC2B
    },
    created() {
      this.originalData = JSON.parse(JSON.stringify(this.editingVehicleModels));
      this.ImageBytes = this.editingVehicleModels.Image;
    },

    props: ['editingVehicleModels'],

    methods: {
      toggleView: function () {
        this.uploadedImageUrl = null;
        this.$emit('closeEditVehicleModels');
      },

      save: function () {
        
        var data = JSON.parse(JSON.stringify(this.editingVehicleModels));
        var payload = {
          data: data,
          files: [],
          success: response => {
            this.$emit('editVehicleModelsSuccess')
          },
          error: error => {
            miniToastr['error'](error, 'Error', 1000, null)
          }
        }
        if(this.uploadedImageData)
        {
          payload.files.push(this.uploadedImageData)
        }
        if(this.uploadedBrochureLinkData)
        {
          payload.files.push(this.uploadedBrochureLinkData)
        }
        if(this.uploadedSpecSheetLinkData)
        {
          payload.files.push(this.uploadedSpecSheetLinkData)
        }
        if(this.uploadedAccessoriesLinkData)
        {
          payload.files.push(this.uploadedAccessoriesLinkData)
        }

        this.$store.dispatch('editVehicleModels', payload);
      },
      updateImageData: function (value) {
        this.uploadedImageData = value;
      },
      updateImageUrl: function (value) {
        this.uploadedImageUrl = value;
      },
      editImage: function () {
        this.editingImage = !this.editingImage;
      },
      updateImage: function () {
        this.editingImage = !this.editingImage;
      },
      updateBrochureLinkData: function (value) {
        this.uploadedBrochureLinkData = value;
      },
      updateBrochureLinkUrl: function (value) {
        this.uploadedBrochureLinkUrl = value;
      },
      editBrochureLink: function () {
        this.editingBrochureLink = !this.editingBrochureLink;
      },
      updateBrochureLink: function () {
        this.editingBrochureLink = !this.editingBrochureLink;
      },

      updateSpecSheetLinkData: function (value) {
        this.uploadedSpecSheetLinkData = value;
      },
      updateSpecSheetLinkUrl: function (value) {
        this.uploadedSpecSheetLinkUrl = value;
      },
      editSpecSheetLink: function () {
        this.editingSpecSheetLink = !this.editingSpecSheetLink;
      },
      updateSpecSheetLink: function () {
        this.editingSpecSheetLink = !this.editingSpecSheetLink;
      },

      updateAccessoriesLinkData: function (value) {
        this.uploadedAccessoriesLinkData = value;
      },
      updateAccessoriesLinkUrl: function (value) {
        this.uploadedAccessoriesLinkUrl = value;
      },
      editAccessoriesLink: function () {
        this.editingAccessoriesLink = !this.editingAccessoriesLink;
      },
      updateBrochureLink: function () {
        this.editingAccessoriesLink = !this.editingAccessoriesLink;
      },


      getImageSource(data) {
        if (this.uploadedImageUrl) {
          return this.uploadedImageUrl;
        }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
          if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
          } else {
            return data.Image;
          }
        }
        return '';
      },
      isNull: function (obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
          return true;
        }
        return false;
      },
      deleteVehicleModels: function () {
        var payload = {
          data: this.editingVehicleModels,
          success: response => {
            this.$emit('editVehicleModelsSuccess')
          },
          error: error => {
            miniToastr['error'](error, 'Error', 1000, null)
          }
        }
        this.$store.dispatch('deleteVehicleModels', payload);
      }
    }
  };

</script>
<style scoped>
  .previewImage {
    max-height: 100px;
  }

</style>
