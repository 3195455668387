
<template>
  <div class="container">
    <h2>Add VehicleModels</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteVehicleModels()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-6">
        <label class for="Name">Name</label>
        <input
          class="form-control"
          id="Name"
          name="Name"
          v-model="newVehicleModels.Name"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <ImageUploader label="Image" v-model="newVehicleModels.Image" />
      </div>
      <div class="col-md-6">
        <label class for="ImageMeta">ImageMeta</label>
        <input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="newVehicleModels.ImageMeta"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class="" for="Commercial">Commercial</label>
        <br />
        <b-form-checkbox
          v-model="newVehicleModels.Commercial"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
      <div class="col-md-6">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newVehicleModels.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="Category">Category</label>
        <input
          class="form-control"
          id="Category"
          name="Category"
          v-model="newVehicleModels.Category"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="StartingPrice">StartingPrice</label>
        <input
          class="form-control"
          id="StartingPrice"
          name="StartingPrice"
          v-model="newVehicleModels.StartingPrice"
          placeholder=" "
          tabindex="0"
          type="text"
        />
   <label class for="StartingPrice">StartingPriceNamibia</label>
        <input
          class="form-control"
          id="StartingPriceNamibia"
          name="StartingPriceNamibia"
          v-model="newVehicleModels.StartingPriceNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
           <label class for="StartingPriceBotswana">StartingPriceBotswana</label>
        <input
          class="form-control"
          id="StartingPriceBotswana"
          name="StartingPriceBotswana"
          v-model="newVehicleModels.StartingPriceBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
           <label class for="StartingPriceSwaziland">StartingPriceSwaziland</label>
        <input
          class="form-control"
          id="StartingPriceSwaziland"
          name="StartingPriceSwaziland"
          v-model="newVehicleModels.StartingPriceSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="StartingPriceMonthly">StartingPriceMonthly</label>
        <input
          class="form-control"
          id="StartingPriceMonthly"
          name="StartingPriceMonthly"
          v-model="newVehicleModels.StartingPriceMonthly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="DisplayOrder">DisplayOrder</label>
        <input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="newVehicleModels.DisplayOrder"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="VideoId">VideoId</label>
        <input
          class="form-control"
          id="VideoId"
          name="VideoId"
          v-model="newVehicleModels.VideoId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader
          :pdf="true"
          label="Brochure Link"
          v-model="newVehicleModels.BrochureLink"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader
          :pdf="true"
          label="SpecSheet Link"
          v-model="newVehicleModels.SpecSheetLink"
        />
      </div>

      <div class="col-md-4">
        <ImageUploader
          :pdf="true"
          label="SpecSheet Link"
          v-model="newVehicleModels.AccessoriesLink"
        />
      </div>

      <!-- <div class='col-md-6'>
        <label class for='SpecSheetLink'>SpecSheetLink</label>
        <input class='form-control' id='SpecSheetLink' name='SpecSheetLink' v-model='newVehicleModels.SpecSheetLink'
          placeholder=' ' tabindex='0' type='text'>

      </div> -->

      <div class="col-md-6">
        <label class for="MainDescription">Main Description</label>
        <textarea
          class="form-control"
          id="MainDescription"
          name="MainDescription"
          v-model="newVehicleModels.MainDescription"
          placeholder=" "
          tabindex="0"
        ></textarea>
      </div>

      <div class="col-md-6">
        <label class for="GalleryDescription">Gallery Description</label>
        <textarea
          class="form-control"
          id="GalleryDescription"
          name="GalleryDescription"
          v-model="newVehicleModels.GalleryDescription"
          placeholder=" "
          tabindex="0"
        ></textarea>
      </div>

      <div class="col-md-6">
        <label class for="ModelsDescription">Models Description</label>
        <textarea
          class="form-control"
          id="ModelsDescription"
          name="ModelsDescription"
          v-model="newVehicleModels.ModelsDescription"
          placeholder=" "
          tabindex="0"
        ></textarea>
      </div>

      <div class="col-md-6">
        <label class for="FeaturesDescription">Features Description</label>
        <textarea
          class="form-control"
          id="FeaturesDescription"
          name="FeaturesDescription"
          v-model="newVehicleModels.FeaturesDescription"
          placeholder=" "
          tabindex="0"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class for="FeaturesDescription"
          >Click to Buy Vehicle Model</label
        >
        <VehicleModelSelectC2B
          v-model="newVehicleModels.ClickToBuyVehicleModelId"
        ></VehicleModelSelectC2B>
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import FileUploader from "@/components/custom/FileUploader.vue";
import DocumentUploader from "@/components/custom/DocumentUploader.vue";
import VehicleModelSelectC2B from "@/components/custom/VehicleModelSelectC2B.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({
  types: toastTypes,
});

export default {
  name: "addVehicleModels",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
      editingBrochureLink: false,
      uploadedBrochureLinkData: {},
      uploadedBrochureLinkUrl: null,
      editingSpecSheetLink: false,
      uploadedSpecSheetLinkData: {},
      uploadedSpecSheetLinkUrl: null,
      editingAccessoriesLink: false,
      uploadedAccessoriesLinkData: {},
      uploadedAccessoriesLinkUrl: null,
      newVehicleModels: {},
    };
  },
  components: {
    FileUploader,
    DocumentUploader,
    ImageUploader,
    VehicleModelSelectC2B,
  },
  created() {
    //this.originalData = JSON.parse(JSON.stringify(this.newVehicleModels));
    // this.ImageBytes = this.newVehicleModels.Image;
  },

  props: [],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditVehicleModels");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.newVehicleModels));
      var payload = {
        data: data,
        files: [],
        success: (response) => {
          this.$emit("addVehicleModelsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.uploadedImageData) {
        payload.files.push(this.uploadedImageData);
      }
      if (this.uploadedBrochureLinkData) {
        payload.files.push(this.uploadedBrochureLinkData);
      }
      if (this.uploadedSpecSheetLinkData) {
        payload.files.push(this.uploadedSpecSheetLinkData);
      }
      if (this.uploadedAccessoriesLinkData) {
        payload.files.push(this.uploadedAccessoriesLinkData);
      }

      this.$store.dispatch("addVehicleModels", payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateBrochureLinkData: function (value) {
      this.uploadedBrochureLinkData = value;
    },
    updateBrochureLinkUrl: function (value) {
      this.uploadedBrochureLinkUrl = value;
    },
    editBrochureLink: function () {
      this.editingBrochureLink = !this.editingBrochureLink;
    },
    updateBrochureLink: function () {
      this.editingBrochureLink = !this.editingBrochureLink;
    },

    updateSpecSheetLinkData: function (value) {
      this.uploadedSpecSheetLinkData = value;
    },
    updateSpecSheetLinkUrl: function (value) {
      this.uploadedSpecSheetLinkUrl = value;
    },
    editSpecSheetLink: function () {
      this.editingSpecSheetLink = !this.editingSpecSheetLink;
    },
    updateSpecSheetLink: function () {
      this.editingSpecSheetLink = !this.editingSpecSheetLink;
    },

    updateAccessoriesLinkData: function (value) {
      this.uploadedAccessoriesLinkData = value;
    },
    updateAccessoriesLinkUrl: function (value) {
      this.uploadedAccessoriesLinkUrl = value;
    },
    editAccessoriesLink: function () {
      this.editingAccessoriesLink = !this.editingAccessoriesLink;
    },
    updateBrochureLink: function () {
      this.editingAccessoriesLink = !this.editingAccessoriesLink;
    },

    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deleteVehicleModels: function () {
      var payload = {
        data: this.newVehicleModels,
        success: (response) => {
          this.$emit("editVehicleModelsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteVehicleModels", payload);
    },
  },
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style>
