<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehicleModelsModal' hide-footer>
        <addVehicleModels @closeAddVehicleModels='toggleAddVehicleModels()' @addVehicleModelsSuccess='addVehicleModelsSuccess()'></addVehicleModels>
      </b-modal>
      <b-modal ref='editVehicleModelsModal' hide-footer>
        <editVehicleModels
          :editingVehicleModels='currentVehicleModels'
          @closeEditVehicleModels='toggleEditVehicleModels()'
          @editVehicleModelsSuccess='editVehicleModelsSuccess()'
        ></editVehicleModels>
      </b-modal>
      <div class='row'>
        <h2>VehicleModels</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicleModels()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
       <DataBlocks
          @itemSelected="editItem"
          :data="items"
          :fields="['Name']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleModels from '../../components/addVehicleModels';
import editVehicleModels from '../../components/editVehicleModels';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'Commercial',
                field: 'Commercial',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
              {
                label: 'Category',
                field: 'Category',
                sort: 'asc',
              },
              {
                label: 'StartingPrice',
                field: 'StartingPrice',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'VideoId',
                field: 'VideoId',
                sort: 'asc',
              },
              {
                label: 'BrochureLink',
                field: 'BrochureLink',
                sort: 'asc',
              },
              {
                label: 'SpecSheetLink',
                field: 'SpecSheetLink',
                sort: 'asc',
              },
              {
                label: 'AccessoriesLink',
                field: 'AccessoriesLink',
                sort: 'asc',
              },
              {
                label: 'MainDescription',
                field: 'MainDescription',
                sort: 'asc',
              },
              {
                label: 'GalleryDescription',
                field: 'GalleryDescription',
                sort: 'asc',
              },
              {
                label: 'ModelsDescription',
                field: 'ModelsDescription',
                sort: 'asc',
              },
              {
                label: 'FeaturesDescription',
                field: 'FeaturesDescription',
                sort: 'asc',
              },
              {
                label: 'StartingPriceMontly',
                field: 'StartingPriceMontly',
                sort: 'asc',
              },
      ],
      items: [],
      addVehicleModelsOpen: false,
      editVehicleModelsOpen: false,
      currentVehicleModels: {}
    };
  },
  components: {
    addVehicleModels,
    editVehicleModels,
    Datatable,
    DataBlocks,
  },
  created: function() {
    this.getVehicleModels();
    this.addVehicleModelsOpen = false;
    this.editVehicleModelsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getVehicleModels() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicleModels', payload);
    },
    editItem(VehicleModels) {
      this.toggleEditVehicleModels();
      this.currentVehicleModels = VehicleModels;
    },
    toggleAddVehicleModels() {
      if(this.addVehicleModelsOpen)
      {
        this.$refs.addVehicleModelsModal.hide()
      }
      else{
        this.$refs.addVehicleModelsModal.show()
      }
      this.addVehicleModelsOpen = !this.addVehicleModelsOpen;
    },
    addVehicleModelsSuccess() {
      this.toggleAddVehicleModels();
      miniToastr['success']('VehicleModels Added', 'Success', 1000, null);
      this.getVehicleModels();
       this.$store.dispatch("updateSearchIndexCache");
    },
    toggleEditVehicleModels() {
      if(this.editVehicleModelsOpen)
      {
        this.$refs.editVehicleModelsModal.hide()
      }
      else{
        this.$refs.editVehicleModelsModal.show()
      }
      this.editVehicleModelsOpen = !this.editVehicleModelsOpen;
    },
    editVehicleModelsSuccess() {
      this.toggleEditVehicleModels();
      miniToastr['success']('VehicleModels Edited', 'Success', 1000, null);
      this.getVehicleModels();
      this.$store.dispatch("updateSearchIndexCache");
    }
  }
};
</script>
<style scoped>
.VehicleModelsThumbnail {
  height: 50px;
}
</style>
